*{
    padding: 0;
    margin: 0;
}

.plans {
    background-color: #fff; /* Set the background color to white */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: none;
  }

  .gridder{
    display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  }



.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}



.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.modal-withdraw .modal-withdraw-inner {
  background: #fff;
  border-color: transparent;
  border-radius: 10px;
  padding: 20px 0px;
  border: 2px solid white !important;
  color: #000;
  margin-top: -50px;
}

.modal-withdraw-inner .withdraws-title {
  font-size: 20px !important;
  font-family: 'Poppins-Bold';
  padding: 0px 20px 20px 20px;
  border-bottom: 1px solid #4e4c4c;
  display:flex;
  flex-direction:raw;
  justify-content:center;
  position:relative;
  color:black;
}
.closebutton{
  position:absolute;
  top:25px;
  right:10px;
}
