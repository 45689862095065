.address-info__card {
    word-wrap: break-word;
    padding: 30px;
    margin-bottom: 0;
    background-color: var(--main-bg);
    box-shadow: var(--box-shadow);
    border: 2px solid;
    border-radius: var(--border-radius);
    border-color: var(--second-color);
    h2 {
        font-size: 20px;
        line-height: 1.35;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &.powered-by {
        width: auto;
        display: table;
        margin: auto;
    }
}

.address-info__footer {
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .address-info__card {
        font-size: 16px;
        padding: 13px 16px;
    }
    .address-info__footer {
        font-size: 12px;
    }
    .address-info__card h2 {
        font-size: 16px;
    }
}