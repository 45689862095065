.layout {
    color: var(--txt-color);
    overflow: hidden;
}

.layout__content {
    background-color: var(--second-bg);
    min-height: 100vh;
}

.layout__content-main {
    padding: 2.75rem;
    position: relative;
}
.layout__content-main {
    position: relative;
    .texture-bg-wrape {
        content: "";
        width: 100%;
        position: absolute;
        background-image: linear-gradient(to right, #A22FFC, #2655E9);
        height: 400px;
        display: inline-block;
        left: 0;
        top: 0;
        opacity: 1;
        // filter: brightness(0.5);
        overflow: hidden;
        img {
            position: absolute;
            left: 0;
            top: 0;
            // transform: scale(2);
            opacity: 1;
        }
    }
}

.layout__content-main:after {
    content: "";
    width: 100%;
    position: absolute;
    height: 400px;
    display: inline-block;
    left: 0;
    top: 0;
    opacity: 1;
    // background: url(../../images/texture-bg.svg);
}

.layout-inner-wrape, .address-info {
    position: relative;
    z-index: 4;
}

.layout__footer {
    padding-left: var(--sidebar-width);
    background-color: var(--main-bg);
    box-shadow: var(--box-shadow);
    min-height: 10vh;
}

@media only screen and (max-width: 1256px) {
    .layout__content {
        padding-left: 0px;
    }

    .sidebar {
        display: none;
    }

    .layout__content-main {
        padding: 2rem;
    }
    .status-card__info > h4 {
        font-size: 1.85rem;
    }
}

@media only screen and (max-width: 900px) {
    .layout__content-main {
        .texture-bg-wrape {
            img {
                transform: scale(1.25);
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .layout__content-main {
        .texture-bg-wrape {
            img {
                transform: scale(1);
                height: 100%;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .layout__content {
        padding-left: 0px;
    }

    .sidebar {
        display: none;
    }
}