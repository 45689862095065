.chart {
    min-height: 350px;
}
.d-flex{
    display: flex;
    .w-40{
        width: 40%;
    }
    .w-60{
        width: 60%;
    }
    .info{
        padding: 0 15px;
    }
}
.info-wrape{
    background-image: linear-gradient(to right, #A22FFC, #2655E9);
}

.status-wrape{
display: flex;
align-items: center;
padding: 50px 20px;
// background: rgba(0, 0, 0, 0.25);
// box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.2);
border-top-left-radius: 10px;
margin-top: 50px;
position: relative;
 &::after {
    background-color: rgba(0, 0, 0, 0.25);
    // top: 0;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    bottom: -16px;
    position: absolute;
    width: 9999px;
    z-index: 0;
    box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.2);
    border-top-left-radius: 10px;
}
}


.chart-bg{
    background: transparent !important;
    box-shadow: none !important;
    border: none !important;
}
.status-card{
    padding: 5px 12px 5px 20px;
    background-color: transparent;
    box-shadow: none;
}
.mt-30{
    padding-top: 30px;
}
.theme-mode-ligh .status-wrape .status-card__info h4,
.theme-mode-dark .status-wrape .status-card__info h4,
.theme-mode-ligh .chart .apexcharts-legend-text,
.theme-mode-ligh .chart .apexcharts-text{
color: #FFF !important;
}
.apexcharts-theme-light .apexcharts-menu-icon svg,
.apexcharts-theme-light .apexcharts-pan-icon svg{
fill: #f3f4f5;
}
.apexcharts-theme-light .apexcharts-zoom-icon svg, .apexcharts-theme-light .apexcharts-zoomin-icon svg, .apexcharts-theme-light .apexcharts-zoomout-icon svg, .apexcharts-theme-light .apexcharts-reset-icon svg, .apexcharts-theme-light .apexcharts-pan-icon svg, .apexcharts-theme-light .apexcharts-selection-icon svg, .apexcharts-theme-light .apexcharts-menu-icon svg, .apexcharts-theme-light .apexcharts-toolbar-custom-icon svg,
.apexcharts-theme-light .apexcharts-yaxis-label,
.apexcharts-theme-light .apexcharts-xaxis-label {
    fill: #f3f4f5;
}

.status-card__info{
    span{
        font-size: 12px;
    }
    h4{
        font-size: 24px;
    }
}
@media only screen and (max-width: 1256px) {
    .row{
        .col-6{
            width: 100%;
            font-size: 12px;
        }
    }
    // .chart {
    //     min-height: 600px;
    // }
}

@media only screen and (max-width: 600px) {
    body {
        font-size: 1.5rem;
    }
    .info {
        .col-6 {
            width: 100%;
        }
    }
    
}