.header-plan {
    text-align: left;
    color: #ffffff;
    margin-bottom: 45px;
    font-weight: 600;
    font-size: 2rem;
    text-transform: uppercase;
    line-height: 1;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    position: relative;
    padding-bottom: 10px
}


.span-menu {
    text-align: center;
    margin-top: 10px;
    color: #ffffff;
    font-weight: 500;
    font-size: 1rem;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    position: relative;
    line-height: 1;
    padding-bottom: 10px;
    margin-left: 10px;
}

.plan-box {
    margin-left: 10px;
    margin-top: 20px;
}

.plan-card{
    text-align: center;
}

.plan-info{
    margin-left: 350px;
}

.ContactUs-email{
    margin-left: 190px;
}

.Contact-name{
    margin-left: 50px;
}

.message{
    margin-left: 50px;
}

.sendmessage-btn{
    margin-left: 485px;
}

