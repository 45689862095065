.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.px-4 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}
