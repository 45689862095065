.header {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // height: var(--header-height);
    background-color: var(--main-bg);
    box-shadow: var(--box-shadow);
    ul.nav-links {
        display: flex;
        gap: 15px;
        margin: 0;
        a {
            padding: 10px;
            position: relative;
            border-bottom: 3px solid transparent;
            &:hover, &.active {
                border-color: var(--main-color);
            }
        }
    }
}
.theme-mode-dark ul.nav-links li .nav-items{
    color: var(--txt-color);
}
.header__search {
    position: relative;
    height: 50px;
    width: 380px;
    background-color: var(--main-bg);
    display: flex;
    align-items: center;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    overflow: hidden;
}

.header__search > input {
    height: 100%;
    width: 100%;
    padding: 10px 60px 10px 20px;
    font-size: 1rem;
    border-radius: var(--border-radius);
    color: var(--txt-color);
    background-color: var(--main-bg);
}

.header__search > i {
    font-size: 1.5rem;
    position: absolute;
    right: 20px;
    cursor: pointer;
}

.header__left {
    display: flex;
    align-items: flex-start;
}

.header__center{
    display: flex;
    align-items: center;
    a:hover{
        color: var(--txt-color);
    }
}

.header__right {
    display: flex;
    align-items: center;
    gap: 10px;
    // margin-right: 1.5rem;
    margin-left: 10px;
}

.header__right-item ~ .header__right-item {
    margin-left: 30px;
}

.header__logo {
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    &:hover{
        // color: var(--second-color-orange);
    }
    h1 {
        font-size: 20px;
        padding-left: 10px;
        margin: 0;
    }
}
.header-right-sec{
    display: flex;
    align-items: center;
}
.header__logo > img {
    height: 50px;
}

@media only screen and (max-width: 1256px) {
    .header__logo {
        font-size: 0px;
    }
    .header__search {
        width: 219px;
    }
}

@media only screen and (max-width: 1365px) {
    .header__search {
        width: 350px;
    }
}
@media only screen and (max-width: 1200px) {
    .header__right {
        margin-right: 1rem;
    }
    .header__logo {
        padding: 0px 15px;
    }
}

@media only screen and (max-width: 900px) {
    .header__logo h1 {
        display: none;
    }
    .header__right {
        margin-right: 0;
    }
}


@media only screen and (max-width: 600px) {
    .header {
        .header__logo > img {
            height: 40px;
        }
        ul.nav-links {
            li {
                font-size: 15px;
                
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    .header {
        ul.nav-links {
            grid-gap: 15px;
            gap: 15px;
            li {
                font-size: 14px;
                a {
                    padding: 10px 0;
                }
            }
        }
        .header__right {
            grid-gap: 0px;
            gap: 0px;
        }
        .header__right-item {
            .theme_menu__toggle {
                position: fixed;
                right: 0;
                top: 120px;
                z-index: 10;
                background: var(--main-bg);
                border-radius: 10px 0 0 10px;
                padding: 8px 5px 8px 10px;
                line-height: 1;
                box-shadow: var(--box-shadow);
                i {
                    font-size: 1.5rem;
                }
            }
        }
    }
}